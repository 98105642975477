import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'

import { AuthState } from '../types'
import { appReducer } from './App/app.reducer'
import { AppState } from './App/app.types'
import { authReducer } from './Auth/auth.reducers'
import { itemsReducer } from './Items/items.reducer'
import { ItemsState } from './Items/items.types'
import { membersReducer } from './Members/members.reducer'
import { MembersState } from './Members/members.types'
import { overviewReducer } from './Overview/overview.reducer'
import { OverviewState } from './Overview/overview.types'
import { requestReducer } from './Request/request.reducer'
import { RequestState } from './Request/request.types'

const rootReducer = combineReducers({
  membersReducer,
  authReducer,
  overviewReducer,
  appReducer,
  itemsReducer,
  requestReducer,
})

export type RootState = {
  appReducer: AppState
  membersReducer: MembersState
  overviewReducer: OverviewState
  authReducer: AuthState
  itemsReducer: ItemsState
  requestReducer: RequestState
}

// Note: this API requires redux@>=3.1.0
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export { store }
