import * as c from '../../constant'
import { updateTableQuery } from '../Items/items.helper'
import { Actions } from './overview.actions'
import { OverviewState } from './overview.types'

const initialState: OverviewState = {
  isLoading: false,
  error: false,
  items: {
    data: [],
    page: 0,
    totalCount: 0,
  },
  query: {
    page: 0,
    pageSize: 10,
    filters: [],
    totalCount: 0,
    search: '',
    orderBy: {
      field: 'ID',
      filterPlaceholder: '...',
      grouping: false,
      headerStyle: { paddingRight: '90px' },
      title: 'ID',
    },
    orderDirection: 'desc',
  },
}

const overviewReducer = (state: OverviewState = initialState, action: Actions): OverviewState => {
  switch (action.type) {
    case c.SET_PAGE:
      return {
        ...state,
        query: action.payload,
      }

    case c.GET_OVERVIEW:
      return {
        ...state,
        isLoading: true,
        error: false,
        items: {
          data: [],
          page: 0,
          totalCount: 0,
        },
      }

    case c.GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        items: action.payload,
        query: {
          ...state.query,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
        },
        isLoading: false,
        error: false,
      }

    case c.GET_OVERVIEW_FAILURE:
      console.log(action.payload.message)
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      }

    case c.SET_OVERVIEW_TABLE_QUERY:
      const query = updateTableQuery({
        query: state.query,
        toUpdate: action.payload,
      })

      return {
        ...state,
        query: {
          ...state.query,
          ...query,
        },
      }

    default:
      return state
  }
}

export { overviewReducer }
