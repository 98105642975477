import * as c from '../../constant'
import { Actions as ItemsActions } from '../Items/items.actions'
import { Actions } from './app.actions'
import { AppState } from './app.types'

const initialState: AppState = {
  openModal: null,
  modalData: false,
  itemStatuses: false,
  isLoadingItemStatuses: false,
  isLoadingModal: false,
}

const appReducer = (state: AppState = initialState, action: Actions | ItemsActions): AppState => {
  switch (action.type) {
    case c.TOGGLE_MODAL:
      return {
        ...state,
        openModal: action.payload.variant,
        modalData: action.payload.data,
      }

    case c.GET_ITEM_STATUSES:
      return {
        ...state,
        isLoadingItemStatuses: true,
      }

    case c.GET_ITEM_STATUSES_SUCCESS:
      return {
        ...state,
        isLoadingItemStatuses: false,
        itemStatuses: action.payload,
      }

    case c.GET_ITEM_STATUSES_FAILURE:
      return {
        ...state,
        isLoadingItemStatuses: false,
      }

    case c.REGENERATE_LABEL:
    case c.UPDATE_ITEM:
      return {
        ...state,
        isLoadingModal: true,
      }

    case c.REGENERATE_LABEL_SUCCESS:
    case c.REGENERATE_LABEL_FAILURE:
    case c.UPDATE_ITEM_SUCCESS:
    case c.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        isLoadingModal: false,
      }

    default:
      return state
  }
}

export { appReducer }
