import React, { FunctionComponent } from 'react'
import { Redirect, RouteComponentProps } from "@reach/router"

type Props = RouteComponentProps & { as: FunctionComponent; isLoggedIn: boolean };

const PublicRoute: FunctionComponent<Props> = ({ as: Component, ...props }) => {
    const { ...rest } = props;
    return !props.isLoggedIn ? <Component {...rest} /> : <Redirect from="" to="/overview" noThrow />;
};

export { PublicRoute }