import axios from 'axios'
import { QueryResult } from 'material-table'
import { Dispatch } from 'redux'

import * as c from '../../constant'
import { platformBearer, platformServerURL } from '../../utils'
import { RootState } from '../combined.reducer'
import { updateTableQuery } from './items.helper'
import { editItem, getItemsService } from './items.service'
import { EditItemRequest, GetTableDataRequest, Item, SetQueryParam } from './items.types'

const getItemsRequest = (payload: GetTableDataRequest) => ({ type: c.GET_ITEMS, payload })
const getItemsSuccess = (payload: QueryResult<Item>) => ({ type: c.GET_ITEMS_SUCCESS, payload })
const getItemsFailure = (payload: Error) => ({ type: c.GET_ITEMS_FAILURE, payload })
export const getItems = (payload: GetTableDataRequest) => {
  return (dispatch: Dispatch) => {
    dispatch(getItemsRequest(payload))
    getItemsService(payload)
      .then(response => dispatch(getItemsSuccess(response)))
      .catch(error => dispatch(getItemsFailure(error)))
  }
}

const regenerateLabelRequest = () => ({ type: c.REGENERATE_LABEL })
const regenerateLabelSuccess = (payload: Item) => ({ type: c.REGENERATE_LABEL_SUCCESS, payload })
const regenerateLabelFailure = (payload: Error) => ({ type: c.REGENERATE_LABEL_FAILURE, payload })
export const regenerateLabel = (payload: number) => {
  return (dispatch: Dispatch) => {
    dispatch(regenerateLabelRequest())
    axios
      .post<Item>(
        `${platformServerURL}/payment/recreate-label`,
        { itemID: payload },
        {
          headers: {
            Authorization: platformBearer,
          },
        }
      )
      .then(
        response => dispatch(regenerateLabelSuccess(response.data)),
        error => dispatch(regenerateLabelFailure(error))
      )
  }
}

const setItemsTableQueryAction = (payload: SetQueryParam) => ({ type: c.SET_ITEMS_TABLE_QUERY, payload })

export const setItemsTableQuery = (payload: SetQueryParam) => {
  return (dispatch: Dispatch, getState: any) => {
    const state: RootState = getState()
    dispatch(setItemsTableQueryAction(payload))

    const query = updateTableQuery({
      query: state.itemsReducer.query,
      toUpdate: payload,
    })

    dispatch(getItems({ query }) as any)
  }
}

const updateItemRequest = () => ({ type: c.UPDATE_ITEM })
const updateItemSuccess = (payload: Item) => ({ type: c.UPDATE_ITEM_SUCCESS, payload })
const updateItemFailure = (payload: Error) => ({ type: c.UPDATE_ITEM_FAILURE, payload })
export const updateItem = (payload: EditItemRequest) => {
  return (dispatch: Dispatch) => {
    dispatch(updateItemRequest())
    editItem(payload)
      .then(response => dispatch(updateItemSuccess(response.data)))
      .catch(error => dispatch(updateItemFailure(error)))
  }
}

export type Actions = ReturnType<
  | typeof regenerateLabelRequest
  | typeof regenerateLabelSuccess
  | typeof regenerateLabelFailure
  | typeof setItemsTableQueryAction
  | typeof getItemsRequest
  | typeof getItemsSuccess
  | typeof getItemsFailure
  | typeof updateItemRequest
  | typeof updateItemSuccess
  | typeof updateItemFailure
>
