import axios from "axios";
import { serverURL } from "../../utils";

const loginUser = async (username: string, password: string) => {
  return axios.post(`${serverURL}auth`, {
    username: username,
    password: password,
  });
};

const verifyToken = async (token: string) => {
  return axios.get(`${serverURL}health`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export { loginUser, verifyToken };
