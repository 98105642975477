import cn from 'classnames'
import React, { SyntheticEvent, useState } from 'react'
import { useDispatch } from 'react-redux'

import { RouteComponentProps, useNavigate } from '@reach/router'

import LoadingSpinner from '../../Components/UI/LoadingSpinner'
import { setLogin } from '../../Shared/Auth/auth.actions'
import { loginUser } from '../../Shared/Auth/auth.service'
import apiHandler from '../../Shared/api'

const Login = (props: RouteComponentProps) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null)

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  // const navigate = useNavigate()
  // const dispatch = useDispatch()

  const handlePassword = (e: React.FormEvent<HTMLInputElement>) => {
    setPassword(e.currentTarget.value)
  }

  const handleUsername = (e: React.FormEvent<HTMLInputElement>) => {
    setUsername(e.currentTarget.value)
  }

  const submitForm = async (e: SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    setError('')
    loginUser(username, password)
      .then(r => {
        setIsLoading(false)
        setError('')
        // localStorage.setItem('token', r.data.token)
        // apiHandler.setAuthorization(r.data.token)
        // dispatch(setLogin(r.data))
        // navigate('/overview')
      })
      .catch(err => {
        setIsLoading(false)
        setError('Unauthorized')
      })
  }

  const handleHover = () => {
    if (buttonRef.current) {
      buttonRef.current.style.position = 'absolute'
      buttonRef.current.style.paddingLeft = '40px'
      buttonRef.current.style.paddingRight = '40px'
      buttonRef.current.style.left = `${Math.ceil(Math.random() * 90)}%`
      buttonRef.current.style.top = `${Math.ceil(Math.random() * 90)}%`
    }
  }

  React.useEffect(() => {
    if (!buttonRef.current) return

    buttonRef.current.addEventListener('mouseover', handleHover)

    return () => {
      buttonRef.current?.removeEventListener('mouseover', handleHover)
    }
  }, [buttonRef])

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <img className="w-20" src="./logo.png" alt="Faundit logo"></img>
      <h1 className="mt-10 text-3xl font-bold font-gothamBold"> Log in to your account </h1>
      <p className="p-2 text-xl font-bold text-red-error">{error}</p>
      <form className="flex flex-col w-5/6 md:w-4/12">
        <div className="flex flex-col my-2">
          <label className="py-2 font-gothamMedium">Username</label>
          <input
            type="text"
            className="p-2 border-2 rounded border-gray-border"
            name="username"
            placeholder="Username"
            value={username}
            onChange={handleUsername}
          />
        </div>
        <div className="flex flex-col my-2">
          <label className="py-2 font-gothamMedium">Password</label>
          <input
            type="password"
            className="p-2 border-2 rounded border-gray-border"
            name="password"
            placeholder="Password"
            value={password}
            onChange={handlePassword}
          />
        </div>
        <button
          ref={buttonRef}
          type="submit"
          className={cn('py-2 my-2 text-lg font-semibold text-white rounded font-gothamMedium transition-all', {
            'bg-red cursor-pointer': !isLoading,
            'bg-disabled cursor-default': isLoading,
          })}
          disabled={isLoading}
        >
          Login
          {isLoading && <LoadingSpinner inline />}
        </button>
      </form>
    </div>
  )
}

export default Login
