import CircularProgress from '@material-ui/core/CircularProgress'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    overlay: {
      background: 'rgba(255, 255, 255, 0.7)',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
    },
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    rootInline: {
      display: 'inline',
      width: '100%',
      height: '100%',
    },
  })
)

type Props = {
  overlay?: boolean
  inline?: boolean
}

export default function LoadingSpinner(props: Props) {
  const classes = useStyles()

  return (
    <>
      {props.overlay ? (
        <div className={classes.overlay}>
          <div className={props.inline ? classes.rootInline : classes.root}>
            <CircularProgress color="secondary" size={props.inline ? 20 : 40} />
          </div>
        </div>
      ) : (
        <div className={props.inline ? classes.rootInline : classes.root}>
          <CircularProgress color="secondary" size={props.inline ? 20 : 40} />
        </div>
      )}
    </>
  )
}
