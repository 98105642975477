import { UPDATE_QUERY } from '../../constant'
import { AuthState } from '../../types'
import { LOGIN, LOGOUT } from './auth.actions'

const defaultAuthState: AuthState = {
  isLoggedIn: false,
  username: '',
}

const authReducer = (state: AuthState = defaultAuthState, action: any): AuthState => {
  switch (action.type) {
    case LOGIN:
      return {
        username: action.payload?.username,
        permissions: action.payload?.permissions,
        isLoggedIn: true,
      }
    case LOGOUT: {
      return {
        ...state,
        isLoggedIn: false,
      }
    }
    case UPDATE_QUERY: {
      return {
        ...state,
        query: action.payload,
      }
    }
    default:
      return state
  }
}

export { authReducer }
