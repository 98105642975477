import "./index.css"

import dotenv from "dotenv"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"

import App from "./App"
import { store } from "./Shared/combined.reducer"

dotenv.config()

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  )
}

render()
