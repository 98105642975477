import * as c from '../../constant'
import { updateTableQuery } from '../Items/items.helper'
import { defaultQuery, defaultTableData } from '../util'
import { Actions } from './members.actions'
import { MembersState } from './members.types'

const initialState: MembersState = {
  isLoadingTable: false,
  member: undefined,
  items: defaultTableData,
  itemsQuery: defaultQuery,
  requests: defaultTableData,
  requestsQuery: defaultQuery,
  users: defaultTableData,
  usersQuery: defaultQuery,
}

const membersReducer = (state: MembersState = initialState, action: Actions): MembersState => {
  let query

  switch (action.type) {
    case c.SET_MEMBER:
      return {
        ...state,
        member: action.payload,
      }

    // Requests
    case c.GET_MEMBER_REQUESTS:
      return {
        ...state,
        isLoadingTable: true,
        requests: {
          data: [],
          page: 0,
          totalCount: 0,
        },
      }

    case c.GET_MEMBER_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.payload,
        requestsQuery: {
          ...state.requestsQuery,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
        },
        isLoadingTable: false,
      }

    case c.SET_MEMBER_REQUESTS_TABLE_QUERY:
      query = updateTableQuery({
        query: state.requestsQuery,
        toUpdate: action.payload,
      })

      return {
        ...state,
        requestsQuery: {
          ...state.requestsQuery,
          ...query,
        },
      }

    // Items
    case c.GET_MEMBER_ITEMS:
      return {
        ...state,
        isLoadingTable: true,
        items: {
          data: [],
          page: 0,
          totalCount: 0,
        },
      }

    case c.GET_MEMBER_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        itemsQuery: {
          ...state.itemsQuery,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
        },
        isLoadingTable: false,
      }

    case c.SET_MEMBER_ITEMS_TABLE_QUERY:
      query = updateTableQuery({
        query: state.itemsQuery,
        toUpdate: action.payload,
      })

      return {
        ...state,
        itemsQuery: {
          ...state.itemsQuery,
          ...query,
        },
      }

    // Users
    case c.GET_MEMBER_USERS:
      return {
        ...state,
        isLoadingTable: true,
        users: {
          data: [],
          page: 0,
          totalCount: 0,
        },
      }

    case c.GET_MEMBER_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        usersQuery: {
          ...state.usersQuery,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
        },
        isLoadingTable: false,
      }

    case c.SET_MEMBER_USERS_TABLE_QUERY:
      query = updateTableQuery({
        query: state.usersQuery,
        toUpdate: action.payload,
      })

      return {
        ...state,
        usersQuery: {
          ...state.usersQuery,
          ...query,
        },
      }

    case c.GET_MEMBER_REQUESTS_FAILURE:
    case c.GET_MEMBER_ITEMS_FAILURE:
    case c.GET_MEMBER_USERS_FAILURE:
      console.log(action.payload.message)
      return {
        ...state,
        isLoadingTable: false,
      }

    default:
      return state
  }
}

export { membersReducer }
