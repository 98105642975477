import { LOGIN, LOGOUT, UPDATE_QUERY } from "../../constant";

import { Permissions } from "../../types";
import { Query } from "material-table";

const setLogin = (data: { permissions: Permissions; username: string }) => {
  return {
    type: LOGIN,
    payload: data,
  };
};

const setLogout = () => {
  return {
    type: LOGOUT,
    payload: null,
  };
};

const updateQuery = (query: Query<object>) => {
  return {
    type: UPDATE_QUERY,
    payload: query,
  };
};

type Actions = ReturnType<typeof setLogin> | ReturnType<typeof setLogout> | ReturnType<typeof updateQuery>;
export { setLogin, LOGIN, setLogout, LOGOUT, updateQuery };
export type { Actions };
