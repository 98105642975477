import { Dispatch } from 'redux'

import * as c from '../../constant'
import { getStatus } from '../Items/items.service'
import { ItemStatus, ToggleModal } from './app.types'

export const toggleModal = (payload: ToggleModal) => {
  return {
    type: c.TOGGLE_MODAL,
    payload,
  } as const
}

const getItemStatusesRequest = () => ({ type: c.GET_ITEM_STATUSES })
const getItemStatusesSuccess = (payload: ItemStatus[]) => ({ type: c.GET_ITEM_STATUSES_SUCCESS, payload })
const getItemStatusesFailure = (payload: Error) => ({ type: c.GET_ITEM_STATUSES_FAILURE, payload })
export const getItemStatuses = () => {
  return (dispatch: Dispatch) => {
    dispatch(getItemStatusesRequest())
    getStatus()
      .then(response => dispatch(getItemStatusesSuccess(response.data.status)))
      .catch(error => dispatch(getItemStatusesFailure(error)))
  }
}

export type Actions = ReturnType<
  typeof toggleModal | typeof getItemStatusesRequest | typeof getItemStatusesSuccess | typeof getItemStatusesFailure
>
