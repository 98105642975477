import React, { useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Redirect, Router } from '@reach/router'

import { DefaultRoute, PrivateRoute, PublicRoute } from './Components/Auth'
import { setLogin } from './Shared/Auth/auth.actions'
import { verifyToken } from './Shared/Auth/auth.service'
import { getToken, removeToken } from './Shared/util'
import { Items, Login, Member, Overview, Request } from './Views'
import { AuthState } from './types'

const NotFound = () => <></>

function App() {
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const dispatch = useDispatch()
  const authReducer: AuthState = useSelector((state: RootStateOrAny) => state.authReducer)

  useEffect(() => {
    const token = getToken()
    if (token) {
      verifyToken(token)
        .then(r => {
          dispatch(setLogin(r.data))
          setIsLoad(true)
        })
        .catch(() => {
          setIsLoad(true)
          removeToken()
        })
    } else {
      setIsLoad(true)
    }
  }, [dispatch])

  return isLoad ? (
    <Router>
      <PublicRoute isLoggedIn={false} path="/" as={Login} />
      <Redirect from="*" to="/" />
      {/* <PrivateRoute isLoggedIn={authReducer.isLoggedIn} path="/overview" as={Overview} />
      <PrivateRoute isLoggedIn={authReducer.isLoggedIn} path="/items" as={Items} />
      <PrivateRoute isLoggedIn={authReducer.isLoggedIn} path="/requests" as={Request} />
      <PrivateRoute isLoggedIn={authReducer.isLoggedIn} path="/member/:memberId" as={Member} />
      <DefaultRoute isLoggedIn={authReducer.isLoggedIn} default as={NotFound} /> */}
    </Router>
  ) : (
    <div></div>
  )
}

export default App
