import { Dialog, DialogActions, Snackbar } from '@material-ui/core'
import cn from 'classnames'
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getItemStatuses, toggleModal } from '../../Shared/App/app.actions'
import { RootState } from '../../Shared/combined.reducer'
import { regenerateLabel, updateItem } from '../../Shared/Items/items.actions'
import { Item } from '../../Shared/Items/items.types'
import { ItemDTO } from '../../types'
import LoadingSpinner from '../UI/LoadingSpinner'

export type EditModalProps = {
  data: Item
}

const EditItemModal = (props: EditModalProps) => {
  const { data } = props
  const dispatch = useDispatch()
  const itemStatuses = useSelector((state: RootState) => state.appReducer.itemStatuses)
  const isLoadingItemStatuses = useSelector((state: RootState) => state.appReducer.isLoadingItemStatuses)
  const isLoadingModal = useSelector((state: RootState) => state.appReducer.isLoadingModal)
  const permissions = useSelector((state: RootState) => state.authReducer.permissions)

  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false)
  const [openErrorSnackBar, setOpenErrorSnackBar] = useState<boolean>(false)

  const [item, setItem] = useState<Item>(data)

  useEffect(() => {
    if (itemStatuses === false) {
      dispatch(getItemStatuses())
    }
  }, []) // eslint-disable-line

  const setValue = (key: keyof Item) => (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setItem(val => ({
      ...val,
      [key]: e.target.value,
    }))
  }

  const handleCloseModal = () => {
    dispatch(toggleModal({ variant: null, data: undefined }))
  }

  const handleFormSubmit = async (event: SyntheticEvent) => {
    try {
      const statusEl = itemStatuses
        ? itemStatuses.filter(s => {
            return String(s.name) === item.status
          })
        : []

      const statusID = statusEl.length ? statusEl[0].ID : undefined

      let itemToEdit: ItemDTO = {}
      Object.keys(item).forEach(key => {
        let k = key as keyof Item
        if (item[k] !== data[k]) itemToEdit[k] = item[k]
      })

      if (item.status !== data.status) itemToEdit.status = statusID

      dispatch(updateItem({ itemID: data.ID, item: itemToEdit }))
      setOpenSnackBar(true)
    } catch (error) {
      if (typeof error === 'string') {
        setOpenErrorSnackBar(true)
      }
    }
  }

  return (
    <div key={data.ID}>
      <Snackbar
        open={openSnackBar}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <p className="px-8 py-2 text-lg bg-white rounded shadow-lg text-green">Item #{data.ID} has been succesfully edited!</p>
      </Snackbar>
      <Snackbar
        open={openErrorSnackBar}
        onClose={() => setOpenErrorSnackBar(false)}
        autoHideDuration={4000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <p className="p-4 text-lg bg-white rounded shadow-lg text-red">Internal error!</p>
      </Snackbar>

      <Dialog open={true} onClose={handleCloseModal} maxWidth="md">
        <div className="p-4">
          <h1 className="ml-4 text-3xl font-gothamMedium">Edit the item</h1>
          <form>
            <div className="flex">
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">First name</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="username"
                  placeholder="First name"
                  value={item.firstName || ''}
                  // onChange={event => setFirstName(event.target.value)}
                  onChange={setValue('firstName')}
                />
              </div>
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">Last name</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="username"
                  placeholder="First name"
                  value={item.lastName || ''}
                  // onChange={event => setFirstName(event.target.value)}
                  onChange={setValue('lastName')}
                />
              </div>
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">Contact email</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="contactEmail"
                  placeholder="Contact email"
                  value={item.contactEmail || ''}
                  // onChange={event => setContactEmail(event.target.value)}
                  onChange={setValue('contactEmail')}
                />
              </div>
            </div>
            <div className="flex">
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">Phone number</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="phoneNumber"
                  placeholder="Phone number"
                  value={item.phoneNumber || ''}
                  // onChange={event => setContactEmail(event.target.value)}
                  onChange={setValue('phoneNumber')}
                />
              </div>
            </div>

            <div className="flex">
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">Street name & number</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="address"
                  placeholder="Street name & number"
                  value={item.address || ''}
                  // onChange={event => setFirstName(event.target.value)}
                  onChange={setValue('address')}
                />
              </div>
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">ZIP</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="zipCode"
                  placeholder="ZIP"
                  value={item.zipCode || ''}
                  // onChange={event => setFirstName(event.target.value)}
                  onChange={setValue('zipCode')}
                />
              </div>
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">City</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="city"
                  placeholder="City"
                  value={item.city || ''}
                  // onChange={event => setcity(event.target.value)}
                  onChange={setValue('city')}
                />
              </div>
            </div>
            {/* <div className="flex">
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">Phone number</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="phoneNumber"
                  placeholder="Phone number"
                  value={item.phoneNumber || ''}
                  // onChange={event => setContactEmail(event.target.value)}
                  onChange={setValue('phoneNumber')}
                />
              </div>
            </div> */}

            <div className="flex">
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">Tracking ID</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="trackingID"
                  placeholder="Tracking ID"
                  value={item.trackingID || ''}
                  // onChange={event => setTrackingID(event.target.value)}
                  onChange={setValue('trackingID')}
                />
              </div>
              <div className="flex flex-col m-4">
                <label className="py-1 font-gothamMedium">Tracking URL</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="trackingURL"
                  placeholder="Tracking URL"
                  value={item.trackingURL || ''}
                  // onChange={event => setTrackingURL(event.target.value)}
                  onChange={setValue('trackingURL')}
                />
              </div>{' '}
              <div className="flex flex-col m-4">
                <label className="py-1 text-black font-gothamMedium ">Shipping label</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="shippingLabel"
                  placeholder="Shipping label"
                  value={item.shippingLabel || ''}
                  // onChange={event => setShippingLabel(event.target.value)}
                  onChange={setValue('shippingLabel')}
                />
              </div>
            </div>
            {/* <div className="flex">
              <div className="flex flex-col w-full m-4">
                <label className="py-1 font-gothamMedium">Item Name</label>
                <input
                  type="text"
                  className="p-2 border-2 rounded shadow-md border-gray-border bg-gray-light"
                  name="itemName"
                  placeholder="Item name"
                  value={item.name || ''}
                  // onChange={event => setName(event.target.value)}
                  onChange={setValue('name')}
                />
              </div>
            </div> */}
            <div className="flex">
              <div className="flex flex-col w-full m-4">
                <label className="py-1 font-gothamMedium">Item Status</label>
                <select
                  name="status"
                  className={cn('p-2 border-2 rounded border-gray-border bg-gray-light', {
                    'bg-disabled border-dark-gray-border border-opacity-20': isLoadingItemStatuses,
                    'bg-gray-light shadow-md': !isLoadingItemStatuses,
                  })}
                  value={item.status}
                  // onChange={event => setStatus(event.target.value)}
                  onChange={setValue('status')}
                  disabled={isLoadingItemStatuses}
                >
                  {itemStatuses
                    ? itemStatuses.map(s => {
                        return (
                          <option value={s.name} key={s.ID}>
                            {s.ID}. {s.name}
                          </option>
                        )
                      })
                    : null}
                </select>
              </div>
            </div>
          </form>

          {permissions?.editItem ? (
            <div className="m-4">
              <button
                onClick={() => dispatch(regenerateLabel(data.ID))}
                className="flex px-4 py-1 text-lg font-regular text-white rounded bg-red"
              >
                Regenerate label
              </button>
            </div>
          ) : null}
          <DialogActions>
            <button onClick={handleCloseModal} className="flex px-2 py-1 text-lg font-semibold text-white rounded bg-gray-dark">
              Close
            </button>
            <button onClick={handleFormSubmit} className="flex px-4 py-1 text-lg font-semibold text-white rounded bg-red">
              Edit
            </button>
          </DialogActions>

          {isLoadingModal && <LoadingSpinner overlay />}
        </div>
      </Dialog>
    </div>
  )
}

export { EditItemModal }
