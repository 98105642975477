import * as c from '../../constant'
import { updateTableQuery } from '../Items/items.helper'
import { defaultQuery, defaultTableData } from '../util'
import { Actions } from './request.actions'
import { RequestState } from './request.types'

const initialState: RequestState = {
  isLoading: false,
  error: false,
  items: defaultTableData,
  query: defaultQuery,
}

const requestReducer = (state: RequestState = initialState, action: Actions): RequestState => {
  switch (action.type) {
    case c.GET_REQUESTS:
      return {
        ...state,
        isLoading: true,
        error: false,
        items: {
          data: [],
          page: 0,
          totalCount: 0,
        },
      }

    case c.GET_REQUESTS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        query: {
          ...state.query,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
        },
        isLoading: false,
        error: false,
      }

    case c.GET_REQUESTS_FAILURE:
      console.log(action.payload.message)
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      }

    case c.SET_REQUEST_TABLE_QUERY:
      const query = updateTableQuery({
        query: state.query,
        toUpdate: action.payload,
      })

      return {
        ...state,
        query: {
          ...state.query,
          ...query,
        },
      }

    default:
      return state
  }
}

export { requestReducer }
