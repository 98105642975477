import { Query } from 'material-table'

const getToken = () => {
  return localStorage.getItem('token')
}

const removeToken = () => {
  return localStorage.removeItem('token')
}

const generateSpecificSearchRequest = (query: any): string => {
  let searchString = ``
  for (let specific of query) {
    const { column, value } = specific
    searchString = `${searchString}${column.field},${value};`
  }
  return searchString.slice(0, -1)
}

const defaultTableData = {
  data: [],
  page: 0,
  totalCount: 0,
}

const defaultQuery: Query<object> = {
  page: 0,
  pageSize: 10,
  filters: [],
  totalCount: 0,
  search: '',
  orderBy: {
    field: 'ID',
    filterPlaceholder: '...',
    grouping: false,
    headerStyle: { paddingRight: '90px' },
    title: 'ID',
  },
  orderDirection: 'desc',
}

export { getToken, removeToken, generateSpecificSearchRequest, defaultTableData, defaultQuery }
