// --- Auth
export const SET_MEMBER = 'SET_MEMBER' as 'SET_MEMBER'
export const LOGIN = 'LOGIN' as 'LOGIN'
export const LOGOUT = 'LOGOUT' as 'LOGOUT'
export const UPDATE_QUERY = 'UPDATE_QUERY' as 'UPDATE_QUERY'

// --- App
export const TOGGLE_MODAL = 'app/TOGGLE_MODAL' as 'app/TOGGLE_MODAL'
export const GET_ITEM_STATUSES = 'app/GET_ITEM_STATUSES' as 'app/GET_ITEM_STATUSES'
export const GET_ITEM_STATUSES_FAILURE = 'app/GET_ITEM_STATUSES_FAILURE' as 'app/GET_ITEM_STATUSES_FAILURE'
export const GET_ITEM_STATUSES_SUCCESS = 'app/GET_ITEM_STATUSES_SUCCESS' as 'app/GET_ITEM_STATUSES_SUCCESS'

// --- Items
export const GET_ITEMS = 'items/GET_ITEMS' as 'items/GET_ITEMS'
export const GET_ITEMS_SUCCESS = 'items/GET_ITEMS_SUCCESS' as 'items/GET_ITEMS_SUCCESS'
export const GET_ITEMS_FAILURE = 'items/GET_ITEMS_FAILURE' as 'items/GET_ITEMS_FAILURE'

export const UPDATE_ITEM = 'items/UPDATE_ITEM' as 'items/UPDATE_ITEM'
export const UPDATE_ITEM_SUCCESS = 'items/UPDATE_ITEM_SUCCESS' as 'items/UPDATE_ITEM_SUCCESS'
export const UPDATE_ITEM_FAILURE = 'items/UPDATE_ITEM_FAILURE' as 'items/UPDATE_ITEM_FAILURE'

export const REGENERATE_LABEL = 'items/REGENERATE_LABEL' as 'items/REGENERATE_LABEL'
export const REGENERATE_LABEL_SUCCESS = 'items/REGENERATE_LABEL_SUCCESS' as 'items/REGENERATE_LABEL_SUCCESS'
export const REGENERATE_LABEL_FAILURE = 'items/REGENERATE_LABEL_FAILURE' as 'items/REGENERATE_LABEL_FAILURE'

export const SET_ITEMS_TABLE_QUERY = 'items/SET_ITEMS_TABLE_QUERY' as 'items/SET_ITEMS_TABLE_QUERY'

// --- Overview
export const GET_OVERVIEW = 'overview/GET_OVERVIEW' as 'overview/GET_OVERVIEW'
export const GET_OVERVIEW_SUCCESS = 'overview/GET_OVERVIEW_SUCCESS' as 'overview/GET_OVERVIEW_SUCCESS'
export const GET_OVERVIEW_FAILURE = 'overview/GET_OVERVIEW_FAILURE' as 'overview/GET_OVERVIEW_FAILURE'

export const SET_PAGE = 'overview/SET_PAGE' as 'overview/SET_PAGE'
export const SET_OVERVIEW_TABLE_QUERY = 'overview/SET_OVERVIEW_TABLE_QUERY' as 'overview/SET_OVERVIEW_TABLE_QUERY'

// --- Requests
export const GET_REQUESTS = 'requests/GET_REQUESTS' as 'requests/GET_REQUESTS'
export const GET_REQUESTS_SUCCESS = 'requests/GET_REQUESTS_SUCCESS' as 'requests/GET_REQUESTS_SUCCESS'
export const GET_REQUESTS_FAILURE = 'requests/GET_REQUESTS_FAILURE' as 'requests/GET_REQUESTS_FAILURE'

export const SET_REQUEST_TABLE_QUERY = 'requests/SET_REQUEST_TABLE_QUERY' as 'requests/SET_REQUEST_TABLE_QUERY'

// --- Member
export const GET_MEMBER_REQUESTS = 'member/GET_MEMBER_REQUESTS' as 'member/GET_MEMBER_REQUESTS'
export const GET_MEMBER_REQUESTS_SUCCESS = 'member/GET_MEMBER_REQUESTS_SUCCESS' as 'member/GET_MEMBER_REQUESTS_SUCCESS'
export const GET_MEMBER_REQUESTS_FAILURE = 'member/GET_MEMBER_REQUESTS_FAILURE' as 'member/GET_MEMBER_REQUESTS_FAILURE'

export const GET_MEMBER_ITEMS = 'member/GET_MEMBER_ITEMS' as 'member/GET_MEMBER_ITEMS'
export const GET_MEMBER_ITEMS_SUCCESS = 'member/GET_MEMBER_ITEMS_SUCCESS' as 'member/GET_MEMBER_ITEMS_SUCCESS'
export const GET_MEMBER_ITEMS_FAILURE = 'member/GET_MEMBER_ITEMS_FAILURE' as 'member/GET_MEMBER_ITEMS_FAILURE'

export const GET_MEMBER_USERS = 'member/GET_MEMBER_USERS' as 'member/GET_MEMBER_USERS'
export const GET_MEMBER_USERS_SUCCESS = 'member/GET_MEMBER_USERS_SUCCESS' as 'member/GET_MEMBER_USERS_SUCCESS'
export const GET_MEMBER_USERS_FAILURE = 'member/GET_MEMBER_USERS_FAILURE' as 'member/GET_MEMBER_USERS_FAILURE'

export const SET_MEMBER_REQUESTS_TABLE_QUERY =
  'member/SET_MEMBER_REQUESTS_TABLE_QUERY' as 'member/SET_MEMBER_REQUESTS_TABLE_QUERY'
export const SET_MEMBER_ITEMS_TABLE_QUERY = 'member/SET_MEMBER_ITEMS_TABLE_QUERY' as 'member/SET_MEMBER_ITEMS_TABLE_QUERY'
export const SET_MEMBER_USERS_TABLE_QUERY = 'member/SET_MEMBER_USERS_TABLE_QUERY' as 'member/SET_MEMBER_USERS_TABLE_QUERY'
