import './styles.css'

import { MuiThemeProvider, createTheme } from '@material-ui/core'
import { Paper } from '@material-ui/core'
import MaterialTable, { Query } from 'material-table'

import { SetQueryParam } from '../../Shared/Items/items.types'
import LoadingSpinner from '../UI/LoadingSpinner'
import PatchedPagination from './TablePagination'

interface Props {
  data: object[]
  query?: Query<object>
  memberId?: string
  columns: any
  isLoading?: boolean
  error?: false | string
  maxBodyHeight?: number

  onQueryChange?(props: SetQueryParam): void
}

const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&&&:before': {
          border: '1px thin #212f3f',
        },
        '&&&:after': {
          border: 'none',
        },
        '&&&:not(.Mui-disabled):hover::before': {
          borderColor: '#212f3f',
        },
      },
    },
  },
})

const Table = (props: Props) => {
  const { data = [], isLoading, error, maxBodyHeight } = props
  const { page, totalCount } = props.query || {}

  const handleQueryChange = (key: keyof Query<object>, value: any, memberId?: string) => {
    if (props.onQueryChange) props.onQueryChange({ key, value, memberId })
  }

  return (
    <>
      <div className="pb-10 table-component">
        {error ? (
          <div className="bg-red-error bg-opacity-10 p-2">
            <p className="text-red">{error}</p>
          </div>
        ) : null}

        <MuiThemeProvider theme={theme}>
          <MaterialTable
            components={{
              Pagination: PatchedPagination,
              OverlayLoading: () => <LoadingSpinner />,
              Container: props => <Paper {...props} />,
            }}
            isLoading={isLoading}
            page={page}
            totalCount={totalCount}
            onChangeRowsPerPage={pageSize => handleQueryChange('pageSize', pageSize, props.memberId)}
            onChangePage={(page, pageSize) => {
              handleQueryChange('page', page, props.memberId)
              handleQueryChange('pageSize', pageSize, props.memberId)
            }}
            onOrderChange={orderBy => {
              if (props.onQueryChange) {
                const orderByObj = props.columns[orderBy]
                // props.onQueryChange({ key: 'orderBy', value: orderByObj })
                handleQueryChange('orderBy', orderByObj, props.memberId)
              }
            }}
            onSearchChange={searchText => handleQueryChange('search', searchText, props.memberId)}
            onFilterChange={filters => handleQueryChange('filters', filters, props.memberId)}
            data={data}
            // data={query => {
            // const requestToken = Axios.CancelToken.source()
            // if (token !== undefined) {
            //   token.cancel()
            // }
            // token = requestToken
            //   console.log(query)

            //   let q = query

            //   if (isFirstRender) {
            //     if (props.initialQuery) {
            //       q = {
            //         ...q,
            //         page: props.initialQuery.page,
            //         pageSize: props.initialQuery.pageSize,
            //         filters: props.initialQuery.filters,
            //         totalCount: props.initialQuery.totalCount,
            //         search: props.initialQuery.search,
            //         // orderBy: props.initialQuery.orderBy,
            //         orderDirection: props.initialQuery.orderDirection,
            //       }
            //     }

            //     setIsFirstRender(false)
            //   }

            //   if (props.onPageChange) {
            //     props.onPageChange({
            //       page: q.page,
            //       pageSize: q.pageSize,
            //       filters: q.filters,
            //       totalCount: q.totalCount,
            //       search: q.search,
            //       orderBy: q.orderBy,
            //       orderDirection: q.orderDirection,
            //     })
            //   }

            //   return props.fetchFunction({
            //     query: q,
            //     memberId: props.memberId,
            //     requestToken,
            //   })
            // }}
            columns={props.columns}
            options={{
              debounceInterval: 1000,
              filtering: true,
              pageSize: 10,
              showTitle: false,
              maxBodyHeight: maxBodyHeight ? `${maxBodyHeight}px` : undefined,
              minBodyHeight: '400px',
              doubleHorizontalScroll: true,
              draggable: false,
              hideFilterIcons: true,
              headerStyle: {
                color: '#212f3f',
                fontSize: '14px',
                fontFamily: 'GothamBold',
              },
              rowStyle: {
                fontSize: '14px',
                fontFamily: 'GothamBook',
                color: '#374151',
              },
              // search: true,
              searchFieldStyle: {
                border: '1px solid #F8F9FB',
                padding: '8px 8px 8px 20px',
                backgroundColor: '#FBECC5',
                borderRadius: '4px',
              },
            }}
          />
        </MuiThemeProvider>
      </div>
    </>
  )
}

export default Table
