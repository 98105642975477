const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const dateDiff = (dateA: Date, dateB: Date): number => {
  return Math.floor((Date.parse(dateB.toDateString()) - Date.parse(dateA.toDateString())) / 86400000)
}

const formatDate = (date: string): string => {
  if (!date || date === '') return date
  let d = date.replace(' ', 'T')
  const dateFormat = new Date(d)
  const daysBetween = dateDiff(dateFormat, new Date())

  if (daysBetween === 0) return 'Today'
  if (daysBetween === 1) return '1 day ago'
  if (daysBetween > 0 && daysBetween < 30) {
    return `${daysBetween} days ago`
  } else if (daysBetween > -30 && daysBetween < 0) {
    return `In ${Math.abs(daysBetween)} days`
  }
  return `${dateFormat.getDate()}. ${monthNames[dateFormat.getMonth()]}. ${dateFormat.getFullYear()}`
}

export const serverURL =
  process.env.NODE_ENV === 'development'
    ? 'http://127.0.0.1:8888/'
    : 'https://6dhwwrpu5d.execute-api.eu-north-1.amazonaws.com/Prod/'

export const platformServerURL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:4000/api' : 'https://db.faundit.com/api'

export const platformBearer =
  process.env.NODE_ENV === 'development'
    ? 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjU0OSwiaG90ZWxJZCI6NTUxLCJ1c2VyUm9sZUlEIjowLCJpYXQiOjE2NDg1NjA4Mzl9.PCeOPFuvnJghyAWQFhDtO655j6hDh1ik0a-zkH620Tk'
    : 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjY0MiwiaG90ZWxJZCI6NjI3LCJ1c2VyUm9sZUlEIjowLCJ1c2VyX3ZlcmlmaWVkIjp0cnVlLCJjc2RBY2Nlc3MiOnRydWUsImlhdCI6MTY1MDM1NzA3NX0.MML7GnIkLLC87shDppQyliHE3dyabKYib0WdmpgTbiY'

// export const serverURL = "https://6dhwwrpu5d.execute-api.eu-north-1.amazonaws.com/Prod/"

export { formatDate }

// https://6dhwwrpu5d.execute-api.eu-north-1.amazonaws.com/Prod/
