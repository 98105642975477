import { Column, Query } from 'material-table'

import { SetQueryParam } from './items.types'

type UpdateTableQuery = {
  query: Query<object>
  toUpdate: SetQueryParam
}

export const updateTableQuery = (props: UpdateTableQuery): Query<object> => {
  const { query, toUpdate } = props

  let orderDirection: 'asc' | 'desc'

  if (toUpdate.key === 'orderBy') {
    const val = toUpdate.value as Column<object>
    if (val.field === query.orderBy.field) {
      orderDirection = query.orderDirection === 'asc' ? 'desc' : 'asc'
    } else {
      orderDirection = 'desc'
    }
  } else {
    orderDirection = query.orderDirection
  }

  const q = {
    ...query,
    [toUpdate.key]: toUpdate.value,
    orderDirection,
  }

  return q
}
