import React from 'react'
import { connect } from 'react-redux'

import { AppState } from '../../Shared/App/app.types'
import { RootState } from '../../Shared/combined.reducer'
import { EditItemModal } from '../Edit/EditItemModal'

type Props = {
  appReducer: AppState
  children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined
}

const Background = (props: Props) => {
  return (
    <div className="h-full bg-gray-light">
      {props.children} {props.appReducer.openModal === 'editItem' ? <EditItemModal data={props.appReducer.modalData} /> : null}
    </div>
  )
}

const mapStateToProps = (state: RootState) => ({
  appReducer: state.appReducer,
})

export default connect(mapStateToProps)(Background)
