import * as c from '../../constant'
import { Actions as AppActions } from '../App/app.actions'
import { Actions } from './items.actions'
import { updateTableQuery } from './items.helper'
import { ItemsState } from './items.types'

const initialState: ItemsState = {
  isLoading: false,
  error: false,
  items: {
    data: [],
    page: 0,
    totalCount: 0,
  },
  query: {
    filters: [],
    page: 0,
    pageSize: 10,
    totalCount: 0,
    search: '',
    orderBy: {
      field: 'ID',
      filterPlaceholder: '...',
      grouping: false,
      headerStyle: { paddingRight: '90px' },
      title: 'ID',
    },
    orderDirection: 'desc',
  },
}

const itemsReducer = (state: ItemsState = initialState, action: Actions | AppActions): ItemsState => {
  switch (action.type) {
    case c.GET_ITEMS:
      return {
        ...state,
        isLoading: true,
        error: false,
        items: {
          data: [],
          page: 0,
          totalCount: 0,
        },
      }

    case c.GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        query: {
          ...state.query,
          page: action.payload.page,
          totalCount: action.payload.totalCount,
        },
        isLoading: false,
        error: false,
      }

    case c.GET_ITEMS_FAILURE:
      console.log(action.payload.message)
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      }

    case c.REGENERATE_LABEL_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          data: state.items.data.map(el => {
            if (el.ID !== action.payload.ID) return el
            return {
              ...el,
              shippingLabel: action.payload.shippingLabel,
              trackingID: action.payload.trackingID,
              trackingURL: action.payload.trackingURL,
            }
          }),
        },
      }

    case c.SET_ITEMS_TABLE_QUERY:
      const query = updateTableQuery({
        query: state.query,
        toUpdate: action.payload,
      })

      return {
        ...state,
        query: {
          ...state.query,
          ...query,
        },
      }

    case c.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        items: {
          ...state.items,
          data: state.items.data.map(el => {
            if (el.ID !== action.payload.ID) return el
            return action.payload
          }),
        },
      }

    default:
      return state
  }
}

export { itemsReducer }
