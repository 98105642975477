import { AxiosError } from 'axios'
import { QueryResult } from 'material-table'

import apiHandler from '../api'
import { ItemStatusApiResponse } from '../App/app.types'
import { IPaginationResult } from '../service.types'
import { generateSpecificSearchRequest } from '../util'
import { EditItemRequest, GetTableDataRequest, Item } from './items.types'

export function getItemsService(props: GetTableDataRequest): Promise<QueryResult<Item>> {
  return new Promise((resolve, reject) => {
    const { query, requestToken } = props

    // var token: CancelTokenSource | undefined
    // const requestToken = axios.CancelToken.source()
    // if (token !== undefined) {
    //   token.cancel()
    // }
    // token = requestToken

    apiHandler
      .axiosGet<IPaginationResult<Item[]>>(`item`, {
        cancelToken: requestToken?.token,
        params: {
          limit: query.pageSize,
          page: query.page,
          searchQuery: query.search,
          orderBy: query.orderBy ? query.orderBy.field : 'ID',
          order: query.orderDirection,
          specificSearchQuery: generateSpecificSearchRequest(query.filters),
        },
      })
      .then(result =>
        resolve({
          data: result.data.data,
          page: result.data.page,
          totalCount: result.data.total,
        })
      )
      .catch((err: AxiosError) => {
        reject(err.response?.data || err)
        // if (axios.isCancel(err)) {
        //   resolve({
        //     data: [
        //       {
        //         ID: '-1',
        //         contactEmail: '',
        //         createdDate: '',
        //         edit: '',
        //         name: '',
        //         firstName: '',
        //         hotelName: '',
        //         image: '',
        //         itemNumber: '',
        //         paymentLink: '',
        //         pickUpDate: '',
        //         shippingLabel: '',
        //         status: '',
        //         trackingURL: '',
        //         lastName: '',
        //         phoneNumber: '',
        //         phoneCode: '',
        //         address: '',
        //         addressNumber: '',
        //         zipCode: '',
        //         country: '',
        //         shipmentID: '',
        //         trackingID: '',
        //       },
        //     ],
        //     page: 0,
        //     totalCount: 0,
        //   })
        // }
        // resolve({
        //   data: [],
        //   page: 0,
        //   totalCount: 0,
        // })
      })
  })
}

export const editItem = async (props: EditItemRequest) => {
  return apiHandler.axiosPatch<Item>(`item`, {
    fields: props.item,
    itemId: props.itemID,
  })
}

export const getStatus = async () => {
  return apiHandler.axiosGet<ItemStatusApiResponse>(`status`)
}
