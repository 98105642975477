import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import { serverURL } from '../utils'
import { getToken } from './util'

class ApiHandler {
  private _authorization: string
  private _baseUrl: string

  constructor() {
    this._authorization = `Bearer ${getToken()}`
    this._baseUrl = serverURL
  }

  axiosGet<T>(path: string, options?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return Axios.get(`${this._baseUrl}${path}`, {
      ...options,
      headers: this.headersGet,
    })
  }

  axiosPost<T>(path: string, data: any, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return Axios.post(`${this._baseUrl}${path}`, data, {
      headers: this.headersPost,
      ...config,
    })
  }

  axiosPut<T>(path: string, data: any): Promise<AxiosResponse<T>> {
    return Axios.put(`${this._baseUrl}${path}`, data, {
      headers: this.headersPost,
    })
  }

  axiosPatch<T>(path: string, data: any): Promise<AxiosResponse<T>> {
    return Axios.patch(`${this._baseUrl}${path}`, data, {
      headers: this.headersPost,
    })
  }

  url(path: string) {
    return `${this._baseUrl}${path}`
  }

  setAuthorization(token: string | null) {
    this._authorization = token ? `Bearer ${token}` : ''
  }

  private get headersGet() {
    return {
      Authorization: this._authorization,
    }
  }

  private get headersPost() {
    return {
      'Content-Type': 'application/json',
      Authorization: this._authorization,
    }
  }

  get baseUrl() {
    return this._baseUrl
  }
}

const apiHandler = new ApiHandler()
export default apiHandler
